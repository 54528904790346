<template>
  <v-card>
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Choose from template
      </h5>
    </v-card-title>
    <v-card-text>
      Click on a reservation below to use it as a template.
    </v-card-text>
    <v-list
      two-line
      class="scroll-list"
    >
      <event
        v-for="(reservation, ind) in webappReservations"
        :key="ind"
        :event="reservation"
      >
        <v-list-tile
          slot-scope="{ formatTitleDate, getStatus }"
          :class="{ selected: ind === selectedIndex }"
          @click="selectItem(ind)"
        >
          <v-list-tile-action>
            <v-tooltip top>
              <status-light
                slot="activator"
                :status="getStatus"
              />
              <span>{{ getStatus }}</span>
            </v-tooltip>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ reservation.reservation_desc || reservation.reservation_title }}
            </v-list-tile-title>
            <v-list-tile-sub-title>
              {{ formatTitleDate() }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </event>
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StatusLight from './StatusLight'
import Event from './Event'
export default {
  components: {
    Event,
    StatusLight
  },
  data () {
    return {
      currentPage: 1,
      numPerPage: 10,
      selectedIndex: -1
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'getReservationsPastAction',
      'getReservationsAction',
      'getReservationAction'
    ]),
    nextPage () {
      if (!this.onLastPage) this.currentPage++
    },
    prevPage () {
      if (!this.onFirstPage) this.currentPage--
    },
    selectItem (ind) {
      this.selectedIndex = ind
      const eventID = this.webappReservations[ind].parent_id || this.webappReservations[ind].original_event
      this.getReservationAction(eventID)
    }
  },
  computed: {
    ...mapGetters('reservation', [
      'webappReservations'
    ]),
    currentItems () {
      const startIndex = (this.currentPage - 1) * this.numPerPage
      return this.originalReservationsPast.slice(startIndex, startIndex + this.numPerPage)
    },
    onFirstPage () {
      return this.currentPage === 1
    },
    onLastPage () {
      return this.currentPage >= this.originalReservationsPast.length / this.numPerPage
    }
  },
  created () {
    this.getReservationsPastAction()
    this.getReservationsAction()
  }
}
</script>

<style>
 .scroll-list {
   max-height: 70vh;
   overflow-y: scroll;
 }
 .selected {
   background-color: gray;
 }
</style>
