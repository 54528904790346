<template>
  <v-card data-cy="facility-form">
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Equipment/Setup Requests
      </h5>
    </v-card-title>
    <v-card-text>
      <v-layout wrap>
        <v-flex xs7>
          <p class="title font-weight-bold text-xs-left">
            Facility Configuration
          </p>
          <v-form>
            <v-text-field
              v-model="facility_prep"
              data-cy="prep"
              :error-messages="textFieldOptionalErrors($v.facility_prep)"
              label="Facility Prep (ex. Don't salt, leave snow)"
              clearable
              @input="$v.facility_prep.$touch()"
              @blur="$v.facility_prep.$touch()"
            />

            <v-text-field
              v-model="facility_its"
              data-cy="its"
              :error-messages="textFieldOptionalErrors($v.facility_its)"
              label="Facility ITS (ex. DSRC Enabled)"
              clearable
              @input="$v.facility_its.$touch()"
              @blur="$v.facility_its.$touch()"
            />

            <v-text-field
              v-model="data_collection"
              data-cy="data"
              :error-messages="textFieldOptionalErrors($v.data_collection)"
              label="Data Collection (ex. Cameras enabled, api access)"
              clearable
              @input="$v.data_collection.$touch()"
              @blur="$v.data_collection.$touch()"
            />

            <p
              class="title font-weight-bold text-xs-left"
            >
              Technician Support*
            </p>

            <v-text-field
              v-model="technician_support"
              data-cy="technician"
              :error-messages="textFieldOptionalErrors($v.technician_support)"
              label="Technician Support (ex. Boom Lift, Cone/Barrier Setup, LPRV Operation)"
              clearable
              @input="$v.technician_support.$touch()"
              @blur="$v.technician_support.$touch()"
            />

            <v-checkbox
              v-model="hardware_install"
              label="Hardware Installation/Removal Required"
              data-cy="hardware_install"
            />

            <v-text-field
              v-model="hardware_desc"
              data-cy="hardware_desc"
              :error-messages="textFieldOptionalErrors($v.hardware_desc)"
              label="Install Details (Type, Power/Data requirements, Proposed Location, etc.)"
              clearable
              :disabled="!hardware_install"
              @input="$v.hardware_desc.$touch()"
              @blur="$v.hardware_desc.$touch()"
            />

            <p style="align: left">
              *Usage rates apply. See <a href="https://mcity.umich.edu/book/">here for details</a>.
            </p>

            <p
              class="title font-weight-bold text-xs-left"
              v-if="(isUserUM || isUserEdu)"
            >
              Partial reservation
            </p>

            <v-select
              v-if="(isUserUM || isUserEdu)"
              v-model="partial_reservation"
              outline
              :items="partialOptions"
              label="UM faculty/staff/students booking the track have the ability to partially book the track at a reduced rate"
            />

            <v-checkbox
              v-model="guest_office"
              label="Guest Office Access"
              data-cy="office"
            />
          </v-form>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import store from '@/store'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { validationMixin } from '../validators'
import { maxLength } from 'vuelidate/lib/validators'
export default {
  mixins: [validationMixin],
  store: store,
  methods: {
    ...mapMutations('reservation', [
      'setReservationField'
    ]),
    createPayload (field, data) {
      return { field, data }
    }
  },
  computed: {
    ...mapState('reservation', {
      companyName: state => state.reservationForm.company_name
    }),
    ...mapState({
      userRoles: state => state.session.userRoles,
      userEmail: state => state.session.user.username
    }),
    ...mapGetters(['isUserEdu']),
    facility_prep: {
      get () {
        return this.$store.state.reservation.reservationForm.facility_prep
      },
      set (value) {
        this.setReservationField(this.createPayload('facility_prep', value))
      }
    },
    facility_its: {
      get () {
        return this.$store.state.reservation.reservationForm.facility_its
      },
      set (value) {
        this.setReservationField(this.createPayload('facility_its', value))
      }
    },
    data_collection: {
      get () {
        return this.$store.state.reservation.reservationForm.data_collection
      },
      set (value) {
        this.setReservationField(this.createPayload('data_collection', value))
      }
    },
    technician_support: {
      get () {
        return this.$store.state.reservation.reservationForm.technician_support
      },
      set (value) {
        this.setReservationField(this.createPayload('technician_support', value))
      }
    },
    hardware_desc: {
      get () {
        return this.$store.state.reservation.reservationForm.hardware_desc
      },
      set (value) {
        this.setReservationField(this.createPayload('hardware_desc', value))
      }
    },
    hardware_install: {
      get () {
        return this.$store.state.reservation.reservationForm.hardware_install
      },
      set (value) {
        this.setReservationField(this.createPayload('hardware_install', value))
      }
    },
    guest_office: {
      get () {
        return this.$store.state.reservation.reservationForm.guest_office
      },
      set (value) {
        this.setReservationField(this.createPayload('guest_office', value))
      }
    },
    partial_reservation: {
      get () {
        return this.$store.state.reservation.reservationForm.partial_reservation
      },
      set (value) {
        if (value === 'Highway') {
          const originalTitle = this.$store.state.reservation.reservationForm.reservation_desc.split('-Urban')
          const title = originalTitle[0] + '-Highway'
          this.setReservationField(this.createPayload('reservation_desc', title))
        } else if (value === 'Urban') {
          const originalTitle = this.$store.state.reservation.reservationForm.reservation_desc.split('-Highway')
          const title = originalTitle[0] + '-Urban'
          this.setReservationField(this.createPayload('reservation_desc', title))
        }
        this.setReservationField(this.createPayload('partial_reservation', value))
      }
    },
    isUserUM () {
      if (this.companyName) {
        return this.userRoles.includes('UM') || this.companyName.toLowerCase().includes('University of Michigan'.toLowerCase())
      } else {
        return this.userRoles.includes('UM')
      }
    },
    partialOptions () {
      const partialOptions = ['Full', 'Highway', 'Urban']
      const alreadyBooked = this.$store.state.reservation.reservationForm.partial_already_booked

      if (alreadyBooked && alreadyBooked.length) {
        delete partialOptions[0]
      } else {
        return partialOptions
      }
      return partialOptions.filter(x => !alreadyBooked.includes(x.toLowerCase()))
    }
  },
  validations: {
    facility_prep: {
      maxLength: maxLength(500)
    },
    facility_its: {
      maxLength: maxLength(500)
    },
    data_collection: {
      maxLength: maxLength(500)
    },
    technician_support: {
      maxLength: maxLength(500)
    },
    hardware_desc: {
      maxLength: maxLength(500)
    }
  }
}
</script>

<style>

</style>
