<template>
  <v-stepper v-model="currentStep">
    <v-stepper-header>
      <template v-for="(step, ind) in steps">
        <v-stepper-step
          :complete="currentStep > ind + 1"
          :step="ind + 1"
          :key="`step-${ind}`"
        >
          {{ step.name }}
        </v-stepper-step>
        <v-divider
          :key="`divider-${ind}`"
          v-if="ind < steps.length - 1"
        />
      </template>
    </v-stepper-header>

    <v-stepper-items class="grey lighten-3">
      <v-stepper-content
        v-for="(step, ind) in steps"
        :key="ind"
        :step="ind + 1"
      >
        <component
          :is="step.component"
          @validation="updateValidation"
          class="mb-2"
          :admin="step.admin"
          :readonly="step.readonly"
        />
        <v-card v-if="isLast">
          <v-card-text class="text-xs-left">
            <a
              target="_blank"
              rel="noopener"
              :href="cancellationPolicyURL"
            >Cancellation Policy</a>
            <v-checkbox
              v-model="policyAgree"
              label="By checking this box you affirm compliance with the Mcity Service Agreement, Safety Training Program, Cancellation Policy and Rate Structure."
              data-cy="stepper-compliance"
            />
          </v-card-text>
        </v-card>
        <v-btn
          color="primary"
          :disabled="isFirst"
          @click="previousStep"
        >
          Previous
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isLast || step.disabled"
          @click="nextStep"
          data-cy="stepper-next"
        >
          Next
        </v-btn>
        <v-btn
          color="primary"
          v-if="isLast"
          :disabled="!policyAgree"
          @click="handleSubmit()"
          data-cy="stepper-submit"
        >
          <div v-if="!saving">
            Submit
          </div>
          <v-progress-circular
            indeterminate
            v-else-if="saving"
            color="white"
          />
        </v-btn>

        <v-dialog
          v-model="partialIssue"
          width="500"
        >
          <v-card>
            <v-card-title class="primary white--text">
              <h2 class="heading">
                Error
              </h2>
            </v-card-title>
            <v-card-text class="text-xs-left">
              Looks like you're trying to book the track, but it's only partially available
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapActions } from 'vuex'
import VehicleForm from './VehicleForm'
import ContactForm from './ContactForm'
import SafetyPlan from './SafetyPlan'
import BillingInfoForm from './BillingInfoForm'
import ChooseExisting from './ChooseExisting'
import FacilityForm from './FacilityForm'
import EquipmentForm from './EquipmentForm'
import ReservationPreview from './ReservationPreview'

export default {
  components: {
    VehicleForm,
    ContactForm,
    SafetyPlan,
    BillingInfoForm,
    ChooseExisting,
    FacilityForm,
    EquipmentForm,
    ReservationPreview
  },
  props: {
    type: {
      type: String,
      default: 'new'
    }
  },
  data () {
    return {
      currentStep: 1,
      saving: false,
      partialIssue: false,
      policyAgree: false,
      cancellationPolicyURL: 'https://mcity.umich.edu/book/',
      componentOrder: [
        {
          component: ContactForm,
          name: 'Contact Form',
          key: 'contact',
          disabled: false
        },
        {
          component: BillingInfoForm,
          name: 'Billing Info',
          key: 'billing',
          disabled: false
        },
        {
          component: VehicleForm,
          name: 'Vehicle Setup',
          key: 'vehicle',
          disabled: false
        },
        {
          component: FacilityForm,
          name: 'Facility Setup',
          key: 'facility',
          disabled: false
        },
        {
          component: EquipmentForm,
          name: 'Equipment Setup',
          key: 'equipment',
          disabled: false
        },
        {
          component: SafetyPlan,
          name: 'Safety Plan',
          key: 'safety',
          disabled: false
        },
        {
          component: ReservationPreview,
          name: 'Reservation Preview',
          key: 'preview',
          disabled: false,
          admin: false
        }
      ],
      ChooseExistingComponent: {
        component: ChooseExisting,
        name: 'Reservation Selection',
        key: 'selection',
        disabled: false,
        readonly: false,
        admin: false
      },
      tourComponentOrder: [
        {
          component: ContactForm,
          name: 'Contact Form',
          key: 'contact',
          disabled: false
        },
        {
          component: BillingInfoForm,
          name: 'Billing Info',
          key: 'billing',
          disabled: false
        },
        {
          component: ReservationPreview,
          name: 'Reservation Preview',
          key: 'preview',
          disabled: false,
          admin: false
        }
      ],
      holdComponentOrder: [
        {
          component: ContactForm,
          name: 'Contact Form',
          key: 'contact',
          disabled: false
        }
      ]
    }
  },
  methods: {
    ...mapActions('reservation/transactions', [
      'createReservationAction',
      'createHoldAction',
      'createTourAction',
      'createTemplateReservationAction',
      'saveReservationAction',
      'isTrackAvailableAction',
      'getReservationsAction'
    ]),
    previousStep () {
      this.currentStep--
    },
    nextStep () {
      this.currentStep++
    },
    updateValidation (event) {
      const step = this.steps.find(item => item.key === event.key)
      step.disabled = event.value
    },
    handleSubmit () {
      this.saving = true
      if (this.$route.name === 'Create') {
        this.isTrackAvailableAction().then((available) => {
          if (available) {
            if (this.type === 'new') {
              this.createReservationAction()
                .then(() => this.$router.push('/'))
            } else if (this.type === 'existing') {
              this.createTemplateReservationAction()
                .then(() => this.$router.push('/'))
            } else if (this.type === 'tour') {
              this.createTourAction()
                .then(() => this.$router.push('/'))
            } else if (this.type === 'hold') {
              this.createHoldAction()
                .then(() => this.$router.push('/'))
            }
          } else {
            this.partialIssue = true
          }
        })
      } else if (this.$route.name === 'Edit') {
        this.saveReservationAction()
          .then(() => this.$router.push('/'))
      }
    }
  },
  computed: {
    steps () {
      if (this.$route.name === 'Create') {
        switch (this.type) {
          case 'new':
            return this.componentOrder
          case 'existing':
            return [this.ChooseExistingComponent, ...this.componentOrder]
          case 'tour':
            return this.tourComponentOrder
          case 'hold':
            return this.holdComponentOrder
        }
      } else if (this.$route.name === 'Edit') {
        // Don't include safety plan or equipment when editing reservation
        return this.componentOrder.filter(item => !['equipment'].includes(item.key))
      }
      return this.componentOrder.filter(item => !['equipment'].includes(item.key))
    },
    isFirst () {
      return this.currentStep === 1
    },
    isLast () {
      return this.currentStep === this.steps.length
    }
  }
}
</script>

<style>

</style>
