<template>
  <v-card data-cy="billing-form">
    <v-card-title class="primary white--text">
      <h5 class="headline">
        Billing Information
      </h5>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-checkbox
          v-model="disableForm"
          label="Billing Info is same as contact info"
          data-cy="copy-info"
        />
        <v-text-field
          v-model="name"
          :disabled="disableForm"
          :error-messages="nameFieldErrors($v.name)"
          label="Full Name"
          hint="ex. John Doe"
          required
          clearable
          @input="$v.name.$touch()"
          @blur="$v.name.$touch()"
        />

        <v-text-field
          v-model="email"
          :disabled="disableForm"
          :error-messages="emailFieldErrors($v.email)"
          label="Contact Email Address"
          hint="ex. uniqname@umich.edu"
          required
          clearable
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        />

        <v-text-field
          v-model="phoneNum"
          :disabled="disableForm"
          :error-messages="numericFieldErrors($v.phoneNum)"
          label="Contact Phone Number"
          hint="ex. 5550123456"
          required
          clearable
          @input="$v.phoneNum.$touch()"
          @blur="$v.phoneNum.$touch()"
        />

        <v-text-field
          v-model="companyAddress"
          :error-messages="textFieldErrors($v.companyAddress)"
          label="Company Address"
          hint="ex. 2905 Baxter Rd, Ann Arbor, MI 48109"
          required
          clearable
          data-cy="address"
          @input="$v.companyAddress.$touch()"
          @blur="$v.companyAddress.$touch()"
        />

        <v-checkbox
          v-model="grant_funded"
          label="Reservation is Grant Funded"
          data-cy="grant-funded"
          @input="$v.grant_funded.$touch()"
        />

        <v-select
          v-if="grant_funded"
          v-model="grant_key"
          :error-messages="grantFieldErrors($v.grant_key)"
          :items="grants"
          item-text="grant_cd"
          item-value="grant_key"
          label="Select Grant Funding Source"
          data-cy="grant-picker"
          required
          @input="$v.grant_key.$touch()"
        />

        <v-text-field
          v-model="details"
          v-if="requiresShortcode"
          :error-messages="detailTextFieldErrors($v.details)"
          label="Additional info (Short code, vendor ID, Account #)"
          hint="ex. 5550123456"
          :required="requiresShortcode"
          clearable
          data-cy="addl-info"
          @input="$v.details.$touch()"
          @blur="$v.details.$touch()"
        />
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import api from '../api'
import { mapState, mapMutations } from 'vuex'
import { required, maxLength, email, numeric, requiredIf } from 'vuelidate/lib/validators'
import { validationMixin, name } from '../validators'
export default {
  mixins: [validationMixin],
  data () {
    return {
      disableForm: false,
      grants: []
      // requiresShortcode: true
    }
  },
  methods: {
    ...mapMutations('reservation', [
      'setReservationField'
    ]),
    fillForm () {

    },
    createPayload (field, data) {
      return { field, data }
    }
  },
  computed: {
    ...mapState('reservation', {
      primaryName: state => state.reservationForm.primary_name,
      primaryEmail: state => state.reservationForm.primary_email,
      primaryPhone: state => state.reservationForm.primary_phone,
      companyName: state => state.reservationForm.company_name
    }),
    ...mapState({
      userRoles: state => state.session.userRoles
    }),
    name: {
      get () {
        return this.$store.state.reservation.reservationForm.billing_name
      },
      set (value) {
        this.setReservationField(this.createPayload('billing_name', value))
      }
    },
    phoneNum: {
      get () {
        return this.$store.state.reservation.reservationForm.billing_phone
      },
      set (value) {
        this.setReservationField(this.createPayload('billing_phone', value))
      }
    },
    email: {
      get () {
        return this.$store.state.reservation.reservationForm.billing_email
      },
      set (value) {
        this.setReservationField(this.createPayload('billing_email', value))
      }
    },
    companyAddress: {
      get () {
        return this.$store.state.reservation.reservationForm.billing_address
      },
      set (value) {
        this.setReservationField(this.createPayload('billing_address', value))
      }
    },
    details: {
      get () {
        return this.$store.state.reservation.reservationForm.billing_info
      },
      set (value) {
        this.setReservationField(this.createPayload('billing_info', value))
      }
    },
    grant_funded: {
      get () {
        return this.$store.state.reservation.reservationForm.grant_funded
      },
      set (value) {
        this.setReservationField(this.createPayload('grant_funded', value))
      }
    },
    grant_key: {
      get () {
        return this.$store.state.reservation.reservationForm.grant_key
      },
      set (value) {
        this.setReservationField(this.createPayload('grant_key', value))
      }
    },
    isUserUM () {
      return this.userRoles.includes('UM') | this.companyName.toLowerCase().includes('University of Michigan'.toLowerCase())
    },
    requiresShortcode () {
      if (this.isUserUM) {
        if (this.grant_funded) {
          var requiresShortcode = false
          this.grants.forEach((grant) => {
            if (grant.grant_key === this.grant_key) {
              if (grant.fraction < 1.0) {
                requiresShortcode = true
              }
            }
          })
          return requiresShortcode
        }
      }
      return true
    },
    grant_selected () {
      return this.grant_key !== 0
    }
  },
  validations: {
    name: {
      required,
      name,
      maxLength: maxLength(100)
    },
    phoneNum: {
      required,
      numeric,
      maxLength: maxLength(10)
    },
    email: {
      required,
      email,
      maxLength: maxLength(100)
    },
    companyAddress: {
      required,
      maxLength: maxLength(300)
    },
    details: {
      maxLength: maxLength(100),
      requiredIf: requiredIf(function () {
        return this.requiresShortcode
      })
    },
    grant_key: {
      grant: function () {
        if (this.grant_funded) {
          if (this.grant_selected) { return true } else { return false }
        } else { return true }
      }

    }
  },
  watch: {
    disableForm (val) {
      if (val) {
        this.name = this.primaryName
        this.email = this.primaryEmail
        this.phoneNum = this.primaryPhone
      }
    },
    '$v.$invalid': {
      immediate: true,
      handler (value) {
        this.$emit('validation', {
          key: 'billing',
          value
        })
      }
    }
  },
  mounted () {
    api.getActiveGrants().then((response) => {
      this.grants = response.data
      const noGrantIndex = this.grants.findIndex(grant => grant.grant_key === 0)
      this.grants[noGrantIndex].grant_cd = 'Please Select A Grant'
      // No Grant key = 0
      this.grant_key = 0
    })
  }
}
</script>

<style>

</style>
